<template>

  <div class="wrapper">

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4" v-show="action_login">
      <h3 class="text-dark text-center mt-1 mb-3"> <span class="text-dark">Login</span> </h3>
      <div class="text-center text-dark mb-3">
        Enter your phone number and Password below
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone number"
              name="mobile" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" :type="type" class="form-control" placeholder="XXXX" aria-label="Enter Password" name="password"
              v-model="password">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon" @click="showPassword"></span>
            </div> -->
          </div>
          <small class="text-dark mb-3">Enter the 4 digit code sent to your phone</small>
          <input type="hidden" name="utm_source" value="">
          <input type="hidden" name="utm_medium" value="">
          <input type="hidden" name="utm_campaign" value="">
          <input type="hidden" name="referrer" value="">
        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By cont for Ponyoka, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms, conditions and policies</a> of Ponyoka
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="login" class="join-button py-2 form-control">Login</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Did you forget your Password? <br>
          No worries!
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" @click="setGetResetCode" class="login-button py-2 form-control">Reset</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-dark mb-3">
          Don't have an account? <br>
          Easy! Join Ponyoka <br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" @click="setSignup" class="login-button py-2 form-control">Join Now</a>
      </div>

    </section>

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4" v-show="action_signup">
      <h3 class="text-dark text-center mt-1 mb-0">Join <span class="text-dark">Ponyoka</span> </h3>
      <div class="text-dark text-center mb-3">To Get Ksh.2500/= Gift</div>
      <div class="form-wrapper mb-3">
        <form class="">
          <div class="mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="tel" class="form-control" placeholder="Phone number 07 or 01" aria-label="Enter Phone Number"
              name="mobile" id="msisdn" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <small v-if="phoneInvalid" class="text-danger text-center m-auto">Invalid phone number</small>
          <div class="mb-3"></div>
          <div class="mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div>   -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="password" class="form-control" placeholder="Password" aria-label="Enter Password"
              name="password" id="signupPassword" v-model="password">
            <!-- <div class="input-group-append" @click="showPassword">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon"></span>
            </div> -->
          </div>
          <div class="mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="password" class="form-control" placeholder="Confirm Password" aria-label="Confirm password"
              name="password_2" id="signupPassword1" v-model="password1">

            <!-- <div class="input-group-append" @click="showPassword">
              <span class="input-group-text"><img :src="btnText" class="input-group-text-icon"></span>
            </div> -->
          </div>
        </form>
      </div>

      <div class="disclaimer text-center text-dark">
        By registering for Ponyoka, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms and Conditions and Privacy Policy</a> of Ponyoka
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="signup" class="join-button py-2 form-control">Get registration code</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Already have an account? <br>
          Welcome back <br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4" v-show="action_verify_password">
      <h3 class="text-dark text-center mt-1 mb-3">Registration <span class="text-dark">Code</span> </h3>
      <div class="text-center text-dark mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <!--<div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Phone number 07 or 01" aria-label="Amount (to the nearest dollar)">
            <div class="input-group-append">
              <span class="input-group-text"></span>
            </div>
          </div>-->
          <div class="mb-0">
            <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div>
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="number" class="form-control" placeholder="Enter code" id="code" aria-label="Enter Code"
              v-model="code">
            <!--<div class="input-group-append">
              <span class="input-group-text"><img src="/img/other/eye.svg" class="input-group-text-icon"></span>
            </div>-->
          </div>
          <small class="text-dark mb-3">Enter the 4 digit code sent to your phone</small>

        </form>
      </div>

      <div class="disclaimer text-center">
        By activating yout Ponyoka account, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms, conditions and policies</a> of Ponyoka
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="verifyAccount" class="join-button py-2 form-control">Activate Account</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Didn’t get an SMS code?<br>
          Let’s try again in<br>
          (15 seconds)
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" href="#n" class="login-button py-2 form-control">Resend</a>
      </div>

    </section>

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4" v-show="action_reset_password">
      <h3 class="text-dark text-center mt-1 mb-3">Reset your <span class="text-dark">Password</span> </h3>
      <div class="text-center text-dark mb-3">
        Enter your phone number
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="text" class="form-control" id="msisdn1" placeholder="Phone number 07 or 01"
              aria-label="Amount (to the nearest dollar)" v-model="msisdn">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>

        </form>
      </div>

      <!--<div class="disclaimer text-center d-none">
        By cont for Ponyoka, you confirm that you are 18 years old or over and agree with the <a class="text-dark">Terms, conditions and policies</a>  of Ponyoka
      </div>-->

      <div class="button-wrapper text-center mb-3">
        <a @click="getResetCode" class="join-button py-2 form-control">Get Reset Code</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" href="#" class="login-button py-2 form-control">Enter SMS Code</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-dark mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4" v-show="action_change_password">
      <h3 class="text-dark text-center mt-1 mb-3">Reset your <span class="text-dark">Password</span> </h3>
      <div class="text-center text-dark mb-3">
        Password reset code has been send to your mobile number. Enter the code you have received in your phone and your
        new password.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><i class="bi bi-phone"></i></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="text" class="form-control" placeholder="Enter code" id="code1" aria-label="Enter Code"
              v-model="code">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
          </div>
          <div class="mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="password" class="form-control" placeholder="New Password" aria-label="New Password"
              name="password" id="signupPassword3" v-model="password">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img src="/img/other/eye.svg" class="input-group-text-icon"></span>
            </div> -->
          </div>
          <div class="mb-0">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text"><img src="/img/other/password.svg" class="input-group-text-icon"></span>
            </div> -->
            <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;" type="password" class="form-control" placeholder="Confirm New Password"
              aria-label="Confirm New Password" name="password_2" id="signupPassword4" v-model="password1">
            <!-- <div class="input-group-append">
              <span class="input-group-text"><img src="/img/other/eye.svg" class="input-group-text-icon"></span>
            </div> -->
          </div>


        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By cont for Ponyoka, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms, conditions and policies</a> of Ponyoka
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="changePassword" class="join-button py-2 form-control">Change Password</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Already have a reset code? <br>
          Proceed to verification<br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" href="#" class="login-button py-2 form-control">Enter SMS Code</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-dark mb-3">
          Already have an account? <br>
          Welcome back<br>
        </div>
        <a style="background-color: var(--yellow); color: var(--blackText);" @click="setLogin" class="login-button py-2 form-control">Login</a>
      </div>

    </section>


  </div>

</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid black;
  border-radius: 5px;
  height: 3em;
  width: 100%;
  color: var(--grey);
  text-align: center;
  /*background-color: var(--grey);*/
}

::placeholder {
  color: var(--grey);
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: var(--grey);
}
</style>

<script>
import axios from "@/services/identity";

export default {
  name: 'Login',
  components: {
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: false,
      action_signup: true,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      btnText: '/img/other/eye.svg'
    }
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }

    }
  },
  methods: {
    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          var status = res.data.status;

          if (parseInt(status) === 201) {

            // take password verification code
            vm.setVerifyAccount();
            return;

          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Success", "Login successful");
          // go to previous page
          console.log('Inititalize MQTT');
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');

          } else if (parseInt(vm.placeBet) === 2) {

            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:outrightbetslip:show');

          } else {

            window.location.href = "/";


          }

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)

            if (parseInt(err.response.status) === 428) {

              vm.setVerifyAccount();
              return;
            }
          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    signup: function () {

      this.reset();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = "/signup";

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");
      // var referral_code = this.getValue("referral_code");
      var referralCodeToSend = this.referralCode;
      var btag = this.getValue("btag")

      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: referrer,
        // referral_code: referral_code,
        referral_code: String(referralCodeToSend),
        btag: btag
      }))
        .then(res => {

          vm.loading = '';

          console.log('rs ' + JSON.stringify(res, undefined, 2))

          const msg = res.data.data;

          // var profile = res.data.message;
          var status = res.data.status;

          if (parseInt(status) === 202) {

            vm.setSuccess("Success", "Registration successful. Please login to proceed");
            vm.setLogin();
            return;

          }


          vm.setSuccess("Signup successful", msg);

          // go to password verification page
          vm.setVerifyAccount();

        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    getResetCode: function () {

      this.reset();

      if (this.msisdn.length < 9) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;
      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn)
      }))
        .then(res => {

          vm.loading = '';
          console.log(JSON.stringify(res));

          vm.setSuccess("Success", "Password reset code has been send to your phone");

          // go to password reset page
          vm.setChangePassword();

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    changePassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        old_password: this.code
      }))
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {

      this.reset();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
        .then(res => {

          vm.loading = '';
          var profile = res.data.message;
          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          this.$router.push({ name: 'home', params: {} });

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    handleBlur: function () {
      if (this.msisdn.length < 9) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        document.getElementById('msisdn').classList.add("is-invalid")

      } else {
        document.getElementById('msisdn').classList.remove("is-invalid")
      }
    },
    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
      if (document.getElementById('signupPassword').type == "password") {
        document.getElementById('signupPassword').type = "text"
        document.getElementById('signupPassword').style.color = "white"
      } else {
        document.getElementById('signupPassword').type = "password"
        document.getElementById('signupPassword').style.color = "rgba(255,255,255,.75)"
      }
      if (document.getElementById('signupPassword1').type == "password") {
        document.getElementById('signupPassword1').type = "text"
        document.getElementById('signupPassword1').style.color = "white"
      } else {
        document.getElementById('signupPassword1').type = "password"
        document.getElementById('signupPassword1').style.color = "rgba(255,255,255,.75)"
      }

      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/other/eyeslash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/other/eye.svg'
      }
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "join");
    this.placeBet = this.getValue("placeBet");


  }
}
</script>